<template>
	<div class="wrapper click_price_button_ip click_inquire_button_ipi">
		<v-container
			class="pa-0 px-0 px-md-3"
			fluid
		>
			<div class="custom_quote">
				<div
					v-if="loading"
					class="loadingWrap"
				>
					<CommonLoaderCircle02
						v-if="loading"
						color="primary"
						class="mt-5"
					/>
				</div>
				<v-col
					cols="12"
					class="pa-0 px-4 title"
					align="center"
				>
					<div
						:class="stamp"
						class="stamp"
					></div>
					<div class="content_title">
						<strong>견적내기</strong>
						<p
							v-if="count === 0 && currentPage === 1"
							class="sub_title"
						>
							몇 가지 <span>항목만 체크</span>하고 시공 <span>상세 견적</span>을 받아보세요.
						</p>
						<p
							v-if="count === 1 && currentPage === 2"
							class="sub_title"
						>
							<span>공간의 상황</span>과 <span>시공범위를</span> 선택해주세요.
						</p>
						<p
							v-if="count === 2 && currentPage === 3"
							class="sub_title"
						>
							원하는 <span>바닥재</span>와 <span>철거 유무</span>를 선택해주세요.
						</p>
						<p
							v-if="count === 3 && currentPage === 4"
							class="sub_title"
						>
							<span>예상 시공 견적가</span>를 확인 후 <span>간편 상담을</span> <span>신청</span>해보세요!
						</p>
					</div>
				</v-col>
				<v-col
					v-show="true"
					cols="12"
					align="center"
					class="pa-0 inner"
				>
					<div
						v-if="count < 4"
						class="slider_progress_bar"
					>
						<div class="bar_area">
							<span
								ref="gauge"
								class="gauge"
							></span>
						</div>
					</div>
					<swiper
						ref="mySwiper"
						class="pt-6"
						:options="swiperOption"
						@slideChange="handleSlideChange"
					>
						<swiper-slide
							v-if="count !== 4"
							class="mb-2"
						>
							<div class="slideitem mx-0">
								<v-col class="pa-0 sns item">
									<div class="ma-0">
										<HomeInteriorPriceDetailinquire01Form
											:currentpage="currentPage"
											:home-type-items1="homeTypeItems_1"
											:width-code-items="widthCodeItems"
											:room-items="roomItems"
											:reset="reset"
											@hometype1="updateddata2"
										/>
										<HomeInteriorPriceDetailinquireButtons
											class="pt-2"
											:count="count"
											:stepdowncalc="stepdowncalc"
											:stepcalc="stepcalc"
											:kindbutton="'클릭'"
											@next="nextpage"
											@prev="prevstep"
										/>
									</div>
								</v-col>
							</div>
						</swiper-slide>
						<swiper-slide
							v-if="count !== 4"
							class="mb-2"
						>
							<div class="slideitem mx-0">
								<v-col class="pa-0 sns item">
									<div class="ma-0">
										<HomeInteriorPriceDetailinquire02Form
											:currentpage="currentPage"
											:home-type-items2="homeTypeItems_2"
											:home-type-items3="homeTypeItems_3"
											:reset="reset"
											@hometype1="updateddata2"
										/>
										<HomeInteriorPriceDetailinquireButtons
											class="pt-2"
											:count="count"
											:stepdowncalc="stepdowncalc"
											:stepcalc="stepcalc"
											:kindbutton="'클릭'"
											@next="nextpage"
											@prev="prevstep"
										/>
									</div>
								</v-col>
							</div>
						</swiper-slide>
						<swiper-slide
							v-if="count !== 4"
							class="mb-2"
						>
							<div class="slideitem mx-0">
								<v-col class="pa-0 sns item">
									<div class="ma-0">
										<HomeInteriorPriceDetailinquire03Form
											:currentpage="currentPage"
											:home-type-items4="homeTypeItems_4"
											:product-model="productModel"
											:kind-model="kindModel"
											:kind-items-filter="kindItemsFilter"
											:product-items-filter="productItemsFilter"
											:select-product-model="selectProductModel"
											:reset="reset"
											@hometype1="updateddata2"
											@selectkind="selectkind"
											@selectproduct="selectproduct"
										/>
										<HomeInteriorPriceDetailinquireButtons
											class="pt-2"
											:count="count"
											:stepdowncalc="stepdowncalc"
											:stepcalc="stepcalc"
											:kindbutton="'클릭'"
											@next="nextpage"
											@prev="prevstep"
										/>
									</div>
								</v-col>
							</div>
						</swiper-slide>
						<swiper-slide
							v-if="count !== 4"
							class="mb-2"
						>
							<div class="slideitem mx-0">
								<v-col class="pa-0 sns item">
									<div class="ma-0">
										<HomeInteriorPriceDetailinquire04Form
											:currentpage="currentPage"
											:home-type-items1="homeTypeItems_1"
											:home-type-items2="homeTypeItems_2"
											:home-type-items3="homeTypeItems_3"
											:home-type-items4="homeTypeItems_4"
											:finaldata="allow"
											:reset="reset"
											@interiorItems="interiorItems"
											@hometype1="updateddata2"
											@userInfo="userInfo"
											@customquote="customquotecalc"
											@checkPrivate="checkPrivate"
											@checkSize="checkSize"
										/>
										<HomeInteriorPriceDetailinquireButtons
											:count="count"
											:stepdowncalc="stepdowncalc"
											:stepcalc="stepcalc"
											:kindbutton="'맞춤견적신청'"
											:quote="checkCall"
											@customquoto="confirmSubmitPopCheck"
											@resetAll="resetcalc"
										/>
									</div>
								</v-col>
							</div>
						</swiper-slide>
						<swiper-slide
							v-if="count === 4"
							class="mb-2"
						>
							<div class="slideitem mx-0">
								<v-col class="pa-0 sns item">
									<div class="ma-0">
										<HomeInteriorPriceDetailinquire05Form
											:currentpage="currentPage"
											:activereset="reset"
										/>
										<HomeInteriorPriceDetailinquireButtons
											class="pt-2"
											:count="count"
											:stepdowncalc="stepdowncalc"
											:stepcalc="stepcalc"
											:kindbutton="'맞춤견적신청'"
											:checkcountall="checkcountall"
											@lastcalc="submitcalc"
											@reset="resetcalc"
										/>
									</div>
								</v-col>
							</div>
						</swiper-slide>
					</swiper>
				</v-col>
			</div>
		</v-container>
		<CommonConfirm
			:dialog="confirmSubmitPop"
			:title="`간편상담 신청`"
			:text="`간편상담 신청을 진행하시겠습니까?`"
			@close="confirmSubmitPop = false"
			@submit="submitlastcalc(4)"
		/>
		<CommonConfirm
			:dialog="confirmResetPop"
			:title="`초기화`"
			:text="`초기화 하시겠습니까?`"
			@close="confirmResetPop = false"
			@submit="callReset(0)"
		/>
	</div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import common from '@/mixins/common'
import admin from '@/mixins/admin'
import ad_kakao from '@/mixins/ad/kakao'
import ad_mobion from '@/mixins/ad/mobion'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

export default {
	name: 'HomeInteriorPriceInputsNew',
	components: {
		Swiper,
		SwiperSlide,
	},
	mixins: [common, admin, ad_kakao, ad_mobion],
	metaInfo: {
		title: '맞춤견적ㅣ동화자연마루',
		meta: [
			{
				vmid: 'title',
				property: 'og:title',
				content: '맞춤견적ㅣ동화자연마루',
			},
			{
				vmid: 'keywords',
				property: 'keywords',
				content:
					'맞춤견적, 신청서, 우리집견적, 마루견적, 견적상담, 업체견적, 비교견적, 인테리어점찾기, 신청서작성',
			},
			{
				vmid: 'description',
				property: 'og:description',
				content:
					'인테리어는 하고싶은데.. 어디서부터 어떻게 해야될지 막막하셨죠? 우리집만을 위한 맞춤견적 서비스를 이용해보세요!',
			},
			{
				vmid: 'url',
				property: 'og:url',
				content: `${process.env.VUE_APP_BASIC_SERVER}/interior/price/new/detailinquire`,
			},
			{
				vmid: 'og:image',
				property: 'og:image',
				content: 'https://www.greendongwha.com/main.png',
			},
		],
	},
	data: () => ({
		swiperOption: {
			effect: 'slide', // 슬라이드 효과 사용
			speed: 0, // 전환 속도를 0으로 설정하여 효과를 없앰
			slidesPerView: 1,
			spaceBetween: 30,
			centeredSlides: false,
			autoHeight: null,
			pagination: {
				el: '.swiper-pagination',
				type: 'progressbar',
			},
			resistance: false, // 슬라이드 터치 저항 여부
			allowTouchMove: false, // 터치 슬라이드  (false-스와이핑안됨)
			slideToClickedSlide: false, //해당슬라이드 클릭시 슬라이드 위치 이동 막기
			observer: true,
			observeParents: true,
			//observeSlideChildren: true,
			on: {
				init: function () {},
				slideChange: function () {
					if (this.activeIndex === 3) {
						//this.params.autoHeight = false
						console.log('this.params', this.params)
					}
					console.log(this.activeIndex)
				},
			},
		},
		//신청
		confirmSubmitPop: false,
		//초기화
		confirmResetPop: false,
		currentPage: 1, // 현재 페이지를 추적하는 변수
		count: 0,
		allow: {
			type_1: null,
			type_2: null,
			type_3: null,
			type_4: null,
			widthModel: null,
			roomModel: null,
			brandModel: null,
			productModel: null,
			content: null,
			est_cost: null,
			est_cost_min: null,
			est_cost_max: null,
			interiorStores: [],
		},
		kindModel: null,
		brandModel: null,
		productModel: null,
		homeTypeItems_1: [
			{ title: '아파트', value: '아파트' },
			{ title: '오피스텔', value: '오피스텔' },
			{ title: '빌라/주택', value: '빌라/주택' },
		],
		homeTypeItems_2: [
			{ title: '베란다 확장', value: 'Y' },
			{ title: '베란다 미 확장', value: 'N' },
		],
		homeTypeItems_3: [
			{ title: '집 전체', value: '집전체' },
			{ title: '거실', value: '거실' },
		],
		homeTypeItems_4: [
			{ title: '철거 진행', value: 'Y' },
			{ title: '철거 미진행', value: 'N' },
		],
		checkcount: 0,
		reset: false,
		checkDataValue: true,
		kindbutton: '',
		expectreceipt: {},
		finalconfirm: false,
		quote: false,
		sliderwidth: 0,
		stamp: 'stamp1',
		pickThisInterior: null,
		hand: null,
		name: null,
		checkprivation: false,
		changeSlder: null,
		loading: false,
	}),
	computed: {
		...mapGetters([
			'ADMIN_GET_PRODUCT_CATEGORY_TYPE_ITEMS',
			'ADMIN_GET_PRODUCT_CATEGORY_BRAND_ITEMS',
			// 제품 데이터
			'HOME_GET_INTERIOR_ESTIMATE_BRAND',
			'HOME_GET_INTERIOR_ESTIMATE_PRODUCT',

			// 평수 , 방 갯수 데이터
			'HOME_GET_INTERIOR_ESTIMATE_EXCLUSIVE_AREA',
			'HOME_GET_INTERIOR_ESTIMATE_ROOM_COUNT',

			// 선택 제품 확인
			'HOME_GET_INTERIOR_SELECT_PRODUCT',
			'HOME_GET_INTERIOR_SELECT_PRICE',

			// 인테리어 업체 확인
			'HOME_GET_INTERIOR_SELECT_INTERIOR',
			'HOME_GET_INTERIOR_AUTO_SELECT_INTERIOR',

			// 회원확인
			'AUTH_GET_USER',
			'AUTH_GET_USER_AUTH',

			// 선택상세정보
			'HOME_GET_INTERIOR_ESTIMATE_SIMPLE_PRICE',

			'HOME_GET_INTERIOR_GET_INTERIOR_ITEMS',
			'HOME_GET_CURRENT_ESTIMATE_CONTENT',

			'HOME_GET_CURRENT_ESTIMATE_PRODUCT',
			'HOME_GET_CURRENT_ESTIMATE_USERINFO',
		]),
		widthCodeItems() {
			return this.HOME_GET_INTERIOR_ESTIMATE_EXCLUSIVE_AREA || []
		},
		roomItems() {
			if (this.allow.widthModel === null) return []
			return (
				this.common_filterItem(
					this.allow.widthModel,
					this.HOME_GET_INTERIOR_ESTIMATE_ROOM_COUNT,
					'exclusive_area',
				) || []
			)
		},
		kindItemsFilter() {
			if (this.brandItemsFilter && this.ADMIN_GET_PRODUCT_CATEGORY_TYPE_ITEMS.length) {
				console.log('brandItemsFilter', this.brandItemsFilter)

				// 추출한 내용의 kind_code만 추출 > 중첩내용 제거
				const typeList = this.brandItemsFilter.map(x => x.kind_code)
				const set = new Set(typeList)
				const setArr = [...set]
				console.log('setArr', setArr)

				// 추출된 kind_code를 통해 전체종류리스트에서 종류List만들기
				let kindItems = this.ADMIN_GET_PRODUCT_CATEGORY_TYPE_ITEMS.filter(x => setArr.includes(x.kind_code))
				console.log('kindItems', kindItems)
				if (!kindItems.length) return []

				kindItems.map(x => (x.active = false)) // active reset
				if (this.kindModel) {
					// 선택데이터 확인 active 체크
					const select = kindItems.findIndex(x => x.kind_code === this.kindModel.kind_code)
					kindItems[select].active = true
				} else {
					// 초기에 선택된 종류 데이터가 없을때 강제로 [0]데이터 active
					kindItems[0].active = true
				}

				kindItems = kindItems.filter(x => x.use_yn === 'Y' && x.subject_code === 'SC_00001')
				return kindItems
			}
			return []
		},
		brandItemsFilter() {
			let filterItems = []
			if (this.HOME_GET_INTERIOR_ESTIMATE_BRAND) {
				// 견적 리스트에 존재하는 브랜드 목록 ( 값에 대한 내용 부족 ) > 전체 브랜드 목록 ( kind_code , kind_name ) 내용 추출
				filterItems = this.HOME_GET_INTERIOR_ESTIMATE_BRAND.map(x => {
					const sameItem = this.ADMIN_GET_PRODUCT_CATEGORY_BRAND_ITEMS.find(
						y => x.brand_code === y.brand_code,
					)
					// console.log('brandItemsFilter', sameItem, this.ADMIN_GET_PRODUCT_CATEGORY_BRAND_ITEMS)
					x.kind_code = sameItem.kind_code
					x.kind_name = sameItem.kind_name
					return x
				})
				console.log('brandItemsFilter', filterItems)
			}
			return filterItems
		},
		productItemsFilter() {
			console.log('HOME_GET_INTERIOR_ESTIMATE_PRODUCT', this.HOME_GET_INTERIOR_ESTIMATE_PRODUCT)
			if (this.brandItemsFilter && this.HOME_GET_INTERIOR_ESTIMATE_PRODUCT) {
				const filterItems = this.HOME_GET_INTERIOR_ESTIMATE_PRODUCT.map(x => {
					const sameData = this.brandItemsFilter.find(y => x.brand_code === y.brand_code)
					x.kind_code = sameData.kind_code
					x.kind_name = sameData.kind_name
					x.brand_name = sameData.brand_name
					x.text = `${sameData.brand_name} ${x.goods_name}`
					return x
				})
				console.log('filterItems', filterItems)
				console.log('filterItems', this.kindItemsFilter)
				console.log('filterItems', this.kindModel)

				if (this.kindModel) {
					return this.common_filterItem(this.kindModel, filterItems, 'kind_code')
				} else {
					console.log('HOME_GET_INTERIOR_ESTIMATE_PRODUCT')
					return this.common_filterItem(this.kindItemsFilter[0], filterItems, 'kind_code')
				}
			}
			return []
		},
		currentPath() {
			return this.common_recentlyRouterPath === '/interior/price/inquire' ? 'components' : 'page'
		},
		allowValue() {
			const checkValue = ['widthModel', 'roomModel']
			for (let i of checkValue) {
				if (this.allow[i] === null) return true
			}

			const checkItems = ['productModel']
			for (let i of checkItems) {
				if (this[i] === null) return true
			}

			return false
		},
		selectProductModel() {
			let text = null
			if (this.productModel) text = `${this.productModel.text}`
			return text
		},

		// eslint-disable-next-line vue/return-in-computed-property
		stepcalc() {
			// if (this.currentPage === 1 && this.checkData(this.newforData.first)) {
			// 	return true
			// }
			const step01 = ['type_1', 'widthModel', 'roomModel']
			const step02 = ['type_2', 'type_3']
			const step03 = ['type_4', 'productModel']
			const step04 = ['content', 'est_cost', 'est_cost_min', 'est_cost_max']
			if (this.currentPage === 1 && this.checkKeysNotNull(this.allow, step01)) {
				return true
			}
			if (this.currentPage === 2 && this.checkKeysNotNull(this.allow, step02)) {
				return true
			}
			if (this.currentPage === 3 && this.checkKeysNotNull(this.allow, step03)) {
				return true
			}
			if (this.currentPage === 4 && this.checkKeysNotNull(this.allow, step04)) {
				return true
			}
			// if(this.currentPage === 3 && this.newforData.radioValues){
			// 	return true
			// }
		},
		// eslint-disable-next-line vue/return-in-computed-property
		lastcalc() {
			if (this.count === 4 && this.checkData(this.newforData.fourth)) {
				return true
			}
		},
		// eslint-disable-next-line vue/return-in-computed-property
		stepdowncalc() {
			if (this.currentPage === 1 || this.currentPage === 4) {
				return true
			}
		},
		// eslint-disable-next-line vue/return-in-computed-property
		checkcountall() {
			if (this.currentPage === 4 && this.finalconfirm) {
				return true
			}
		},
		changesliderwidth() {
			return this.currentPage
		},
		// checksubmit(val){
		// 	if(val){
		// 		return (this.finalconfirm = val)
		// 	}
		// },
		authAgencyCheck() {
			return this.AUTH_GET_USER_AUTH === 'AGENCY'
		},
		authGuestCheck() {
			return this.AUTH_GET_USER_AUTH === 'GUEST'
		},
		authUserCheck() {
			return this.AUTH_GET_USER_AUTH === 'USER'
		},
		checkCall() {
			if (this.authGuestCheck) {
				if (
					this.allow.interiorStores?.length !== 0 &&
					//this.allow.content !== null &&
					this.allow.est_cost !== null &&
					this.currentPage === 4 &&
					this.checkprivation &&
					this.hand &&
					this.name
				) {
					return true
				} else {
					return false
				}
			} else if (this.authUserCheck) {
				if (
					this.allow.interiorStores?.length !== 0 &&
					//this.allow.content !== null &&
					this.allow.est_cost !== null &&
					this.currentPage === 4 &&
					this.checkprivation
				) {
					return true
				} else {
					return false
				}
			} else {
				return false
			}
		},
		checkChangeSlider() {
			return this.changeSlder
		},
	},
	watch: {
		authAgencyCheck(val) {
			console.log('this.AUTH_GET_USER_AUTH', val)
			if (val) this.noPermission()
		},
		brandModel(val) {
			this.allow.brandModel = val
			this.$emit('change', { title: 'brand', item: val })
		},
		productModel(val) {
			this.allow.productModel = val
			this.$emit('change', { title: 'product', item: val })
		},
		count(val) {
			if (val === 0) {
				this.stamp = 'stamp1'
			}
			if (val === 1) {
				this.stamp = 'stamp2'
			}
			if (val === 2) {
				this.stamp = 'stamp3'
			}
			if (val === 3) {
				this.submit()
				this.stamp = 'stamp4'
				// // google analytics
				// window.gtag('event', 'click_price_button', { button_location: 'interior_price' })
				// // ad_kakao click
				// this.ad_kakao_clickScript(null, 'click_price_button_ip')
				// // ad_mobion click
				// this.ad_mobion_click_convertion('click_price_button_ip')
			}
		},
		changesliderwidth(val) {
			let sliderlength = this.$refs.mySwiper.$el.swiper.slides.length
			let num = 100 / sliderlength

			if (val && this.count < sliderlength) {
				const res = num * val
				this.sliderwidth = res
				this.changewidthvalue(this.sliderwidth)
			}
		},
		checkChangeSlider(newval) {
			this.swiperOption.autoHeight = newval
		},
	},
	async created() {
		this.changeSlder = true
		this.swiperOption.autoHeight = this.changeSlder
		console.log(this.swiperOption.slidesPerView)
	},
	async mounted() {
		// 브랜드, 제품 , 평수 , 방 데이터 가져오기
		await this.getData()
		// 바닥재 제품 견적 가능한 상품 선택시 제품 등록
		if (this.brand) {
			this.brandModel = this.brandItemsFilter.find(x => x.brand_code === this.brand.brand_code)
			this.allow.brandModel = this.brandModel
		}
		if (this.product) {
			this.productModel = this.productItemsFilter.find(x => x.goods_code === this.product.goods_code)
			this.allow.productModel = this.productModel
		}

		let sliderlength = this.$refs.mySwiper.$el.swiper.slides.length
		console.log('랭스', sliderlength)
		this.sliderwidth = 100 / sliderlength

		const gaugewidth = this.$refs.gauge
		gaugewidth.style.width = `${this.sliderwidth}%`

		//this.swiper = new Swiper(this.$refs.mySwiper.$el, this.swiperOption)
		console.log(this.swiper)
	},
	destroyed() {},
	methods: {
		...mapMutations([
			'HOME_MU_INTERIOR_SELECT_PRICE',
			'APP_MU_DIALOG',
			'HOME_MU_CURRENT_ESTIMATE_CONTENT_ITEM',
			'HOME_MU_CURRENT_ESTIMATE_CONTENT_PAGETYPE',
			'HOME_MU_CURRENT_ESTIMATE_CONTENT_INTERIORSELECT',
			'HOME_MU_CURRENT_ESTIMATE_CONTENT_INTERIORSELECTAUTO',
			'HOME_MU_CURRENT_ESTIMATE_CONTENT_ASKCONTENT',
			'HOME_MU_INTERIOR_ESTIMATE_SIMPLE_PRICE',
			'HOME_MU_CURRENT_ESTIMATE_CONTENT_PRODUCT',
			'HOME_MU_CURRENT_ESTIMATE_CONTENT_USERINFO',
		]),
		...mapActions([
			// 종류 가져오기
			'ADMIN_ACT_PRODUCT_GET_CATEGORY_ITEMS',
			// 브랜드, 제품 선택 데이터 가져오기
			'HOME_ACT_INTERIOR_ESTIMATE_BRAND',
			'HOME_ACT_INTERIOR_ESTIMATE_PRODUCT',
			// 평수, 방 갯수 선택 데이터 가져오기
			'HOME_ACT_INTERIOR_ESTIMATE_EXCLUSIVE_AREA',
			'HOME_ACT_INTERIOR_ESTIMATE_ROOM_COUNT',
			// 간편견적
			'HOME_ACT_INTERIOR_ESTIMATE_SIMPLE_PRICE',
		]),
		// slideChangeTransitionStart() {
		// 	console.log('현재index', this.$swiper.activeIndex); //현재 index값 얻기
		// },

		confirmSubmitPopCheck(val) {
			if (val === 4) {
				this.confirmSubmitPop = true
			}
		},

		// 비회원 정보
		userInfo(val) {
			this.name = val.name
			this.hand = val.hand
		},
		selectkind(item) {
			this.kindModel = item
		},
		selectproduct(item) {
			this.productModel = item
		},

		async getData() {
			if (!this.ADMIN_GET_PRODUCT_CATEGORY_TYPE_ITEMS.length) await this.ADMIN_ACT_PRODUCT_GET_CATEGORY_ITEMS('2') // 제품
			if (!this.ADMIN_GET_PRODUCT_CATEGORY_BRAND_ITEMS.length)
				await this.ADMIN_ACT_PRODUCT_GET_CATEGORY_ITEMS('3') // 브랜드 전체 정보
			if (this.HOME_GET_INTERIOR_ESTIMATE_BRAND === null) await this.HOME_ACT_INTERIOR_ESTIMATE_BRAND() // 브랜드
			if (this.HOME_GET_INTERIOR_ESTIMATE_PRODUCT === null) await this.HOME_ACT_INTERIOR_ESTIMATE_PRODUCT() // 제품
			if (this.HOME_GET_INTERIOR_ESTIMATE_EXCLUSIVE_AREA === null)
				await this.HOME_ACT_INTERIOR_ESTIMATE_EXCLUSIVE_AREA() // 평수
			if (this.HOME_GET_INTERIOR_ESTIMATE_ROOM_COUNT === null) await this.HOME_ACT_INTERIOR_ESTIMATE_ROOM_COUNT() // 방
		},
		// 특정 데이터 null값 확인함수의 key 덩어리들을 확인
		checkObj(val) {
			for (const key in val) {
				const obj = val[key]
				if (this.checkData(obj)) {
					return true
				} else {
					return false
				}
			}
		},
		// 특정 데이터 null값 확인
		checkData(obj) {
			for (const key in obj) {
				if (obj[key] === null || obj[key] === '') {
					return false
				}
			}
			return true
		},
		// 객체형태 데이터 null값 확인
		checkKeysNotNull(obj, keys) {
			const res = keys.map(v => obj[v] === null || obj[v] === undefined)
			return res.every(v => v === false)
		},

		handleSlideChange() {
			console.log(this.$refs.mySwiper.$el.swiper)

			if (this.$refs.mySwiper.$el.swiper.realIndex === 2 || this.$refs.mySwiper.$el.swiper.realIndex === 3) {
				this.changeSlder = false
			} else {
				this.changeSlder = true
			}

			this.currentPage = this.$refs.mySwiper.$el.swiper.realIndex + 1
		},
		updateddata2(val, hint) {
			if (hint === 'form1') {
				this.checkcount = 1
				this.allow.type_1 = val.type_1
				this.allow.widthModel = val.widthModel
				this.allow.roomModel = val.roomModel
			}
			if (hint === 'form2') {
				this.checkcount = 2
				this.allow.type_2 = val.type_2
				this.allow.type_3 = val.type_3
			}
			if (hint === 'form3') {
				this.checkcount = 3
				this.allow.type_4 = val.type_4
			}

			if (hint === 'form4') {
				this.checkcount = 4
				this.allow.content = val.content
				this.allow.est_cost = val.est_cost
				this.allow.est_cost_min = val.est_cost_min
				this.allow.est_cost_max = val.est_cost_max
				this.allow.interiorStores = val.interiorStores
			}
			if (hint === 'reset') {
				this.checkcount = 0
				this.allow.type_1 = val
				this.allow.type_2 = val
				this.allow.type_3 = val
				this.allow.type_4 = val
				this.allow.widthModel = val
				this.allow.roomModel = val
				this.allow.brandModel = val
				this.allow.productModel = val
				this.allow.content = val
				this.allow.est_cost = val
				this.allow.est_cost_max = val
				this.allow.est_cost_min = val
				this.allow.interiorStores = val
				this.productModel = val
			}
		},
		//맞춤견적
		submitcalc(val) {
			this.count = val
			this.$nextTick(() => {
				this.$refs.mySwiper.$el.swiper.slideTo(this.count)
			})
		},
		//맞춤견적(테스트)
		async submitlastcalc(val) {
			console.log('맞춤견적', val)
			console.log('인테리어점들', this.pickThisInterior.length)
			if (val === 4) {
				if (this.pickThisInterior.length === 0 || null) {
					alert('인테리어점을 선택 해주세요.')
					return
				}
				if (this.authAgencyCheck) {
					this.noPermission()
					return
				}
				this.confirmSubmitPop = false
				this.loading = true
				let items = {
					method: 'post',
					params: {
						memberYN: this.AUTH_GET_USER.login_id ? 'Y' : 'N',
						login_id: this.AUTH_GET_USER.login_id ? this.AUTH_GET_USER.login_id : this.hand,
						login_name: this.AUTH_GET_USER.name ? this.AUTH_GET_USER.name : this.name,
						regi_userid: this.AUTH_GET_USER.login_id ? this.AUTH_GET_USER.login_id : this.name,

						// 문의 사항 등록정보
						// zipcode: this.post, //첨부파일
						// address1: this.addressBasic, //주소
						// address2: this.addressDetail,//주소
						content: this.allow.content,
						est_cost: this.allow.est_cost,
						est_cost_max: this.allow.est_cost_max,
						est_cost_min: this.allow.est_cost_min,
						housetype: this.homeTypeItems_1[this.allow.type_1].value, //집유형
						exclusive_area: this.allow.widthModel.exclusive_area, //평형
						roomcount: this.allow.roomModel.roomcount, //방개수
						veranda_yn: this.homeTypeItems_2[this.allow.type_2].value, //베란다 확장여부
						buildscope: this.homeTypeItems_3[this.allow.type_3].value, //시공범위
						goods_code: this.allow.productModel.goods_code, //제품
						removal_yn: this.homeTypeItems_4[this.allow.type_4].value, //철거여부
					},
				}

				// urlHistory 등록
				items.params.trackingList = localStorage.urlHistory?.get().join(',')

				if (this.HOME_GET_INTERIOR_GET_INTERIOR_ITEMS.length !== 0) {
					// interior 업체 등록
					this.HOME_GET_INTERIOR_GET_INTERIOR_ITEMS.forEach((x, i) => {
						const num = i + 1
						items.params[`corporate_no${num}`] = x.corporate_no
						items.params[`hand${num}`] = x.manager_hand
						items.params[`sms_yn${num}`] = x.sms_yn
					})

					if (this.HOME_GET_INTERIOR_SELECT_PRODUCT) {
						// 제품을 선택한 경우 ( 상세제품 - '상품마스터' / 3D 집들이 - '3D집들이관리' / 전문가 집들이 - '전문가집들이관리' )
						items.params.product_kind = this.HOME_GET_INTERIOR_SELECT_PRODUCT.type
						items.params.product_id = this.HOME_GET_INTERIOR_SELECT_PRODUCT.contents.id
						items.params.product_code = this.HOME_GET_INTERIOR_SELECT_PRODUCT.goods_code
					}
				} else {
					// interior 업체 등록
					this.HOME_GET_INTERIOR_AUTO_SELECT_INTERIOR.forEach((x, i) => {
						const num = i + 1
						items.params[`corporate_no${num}`] = x.corporate_no
						items.params[`hand${num}`] = x.manager_hand
						items.params[`sms_yn${num}`] = x.sms_yn
					})

					if (this.HOME_GET_INTERIOR_SELECT_PRODUCT) {
						// 제품을 선택한 경우 ( 상세제품 - '상품마스터' / 3D 집들이 - '3D집들이관리' / 전문가 집들이 - '전문가집들이관리' )
						items.params.product_kind = this.HOME_GET_INTERIOR_SELECT_PRODUCT.type
						items.params.product_id = this.HOME_GET_INTERIOR_SELECT_PRODUCT.contents.id
						items.params.product_code = this.HOME_GET_INTERIOR_SELECT_PRODUCT.goods_code
					}
				}

				items.params.product_kind = '견적내기'

				await this.HOME_ACT_INTERIOR_ESTIMATE_SIMPLE_PRICE(items)
					.then(async res => {
						if (res) {
							this.HOME_MU_CURRENT_ESTIMATE_CONTENT_ITEM(this.pickThisInterior)
							this.HOME_MU_CURRENT_ESTIMATE_CONTENT_PAGETYPE('견적내기')
							// const currentUserinfo = {
							// 	name: this.name,
							// 	hand: this.hand,
							// }
							// this.HOME_MU_CURRENT_ESTIMATE_CONTENT_USERINFO(currentUserinfo)
							this.HOME_MU_CURRENT_ESTIMATE_CONTENT_PRODUCT(items.params)
							const userinfo = {
								name: this.name,
								hand: this.hand,
							}
							this.HOME_MU_CURRENT_ESTIMATE_CONTENT_USERINFO(userinfo)

							this.loading = false

							this.$router.push({ path: '/interior/price/new/finalinquire' })

							// this.count = val
							// this.$refs.mySwiper.$el.swiper.slideTo(this.count)
						} else {
							await this.APP_MU_DIALOG({
								dialog: true,
								title: '견적내기',
								text: '견적내기 접수를 실패하였습니다.',
							})
						}
					})
					.catch(e => {
						console.log('HOME_ACT_INTERIOR_ESTIMATE_SIMPLE_PRICE', e)
					})

				console.log('맞춤견적 넘어갈때 파라미터', items)
			}
		},
		prevstep(val) {
			this.count = val
			this.$refs.mySwiper.$el.swiper.slideTo(this.count)
		},
		nextpage(val) {
			this.count = val
			this.$refs.mySwiper.$el.swiper.slideTo(this.count)
		},
		resetcalc(val) {
			if (val === 0) {
				this.confirmResetPop = true
			}
		},
		callReset(val) {
			this.allow.housetype = null
			this.allow.type_1 = null
			this.allow.type_2 = null
			this.allow.type_3 = null
			this.allow.type_4 = null
			this.allow.widthModel = null
			this.allow.roomModel = null
			this.allow.brandModel = null
			this.allow.productModel = null
			this.productModel = null

			this.quote = false
			this.confirmResetPop = false

			this.currentPage = 1
			this.count = val
			this.reset = !this.reset
			this.updateddata2(null, 'reset')
			this.$refs.mySwiper.$el.swiper.slideTo(this.count)
		},
		customquotecalc(val) {
			if (val && this.currentPage === 4) {
				this.quote = val
			}
		},

		async submit() {
			// 잠시 에러떠서 주석
			// if (this.currentPath === 'page') {
			// 	// google analytics
			// 	window.gtag('event', 'click_price_button', { button_location: 'interior_price' })
			// 	// ad_kakao click
			// 	this.ad_kakao_clickScript(null, 'click_price_button_ip')
			// 	// ad_mobion click
			// 	this.ad_mobion_click_convertion('click_price_button_ip')
			// }

			const items = {
				method: 'get',
				params: {
					housetype: this.homeTypeItems_1[this.allow.type_1].value,
					exclusive_area: this.allow.roomModel.exclusive_area,
					roomcount: this.allow.roomModel.roomcount,
					veranda_yn: this.homeTypeItems_2[this.allow.type_2].value,
					buildscope: this.homeTypeItems_3[this.allow.type_3].value,
					removal_yn: this.homeTypeItems_4[this.allow.type_4].value,
					goods_code: this.allow.productModel.goods_code,
				},
			}

			await this.HOME_ACT_INTERIOR_ESTIMATE_SIMPLE_PRICE(items).then(res => {
				if (this.currentPath === 'components') this.HOME_MU_INTERIOR_SELECT_PRICE(res)
				//this.$emit('submit', this.allow)
				this.expectreceipt = res
				console.log('이모지', this.expectreceipt)
			})
		},

		changewidthvalue(val) {
			this.sliderwidth = val
			const gaugewidth = this.$refs.gauge
			gaugewidth.style.width = `${this.sliderwidth}%`
		},
		interiorItems(val) {
			if (val) {
				this.pickThisInterior = val
			}
		},
		// 신청 권한 제한
		noPermission() {
			this.$toastr.warning('인테리어 회원은 견적상담기능을 사용하실 수 없습니다.', '견적상담 불가')
		},
		checkPrivate(val) {
			this.checkprivation = val
		},
		checkSize(val) {
			if (val) {
				this.$nextTick(() => {
					document.querySelector('.swiper-wrapper').style.height = 'auto'
				})
			}
		},
	},
}
</script>

<style scoped lang="scss">
.custom_quote {
	position: relative;
	.title {
		max-width: 468px;
		margin: 0 auto;
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		.stamp {
			height: 90px;
			width: 100px;
			left: -10px;
			top: 28px;
			border-radius: 5px;
			position: absolute;
			z-index: 1;
		}
		.stamp1 {
			background: url('../../../../assets/img/estimate/step01.svg') no-repeat;
			background-size: inherit;
			background-position: 0, 0;
			-webkit-animation-name: pulse;
			animation-name: pulse;
			animation-delay: 1s;
			-webkit-animation-delay: 1s;
			-webkit-animation-duration: 0.5s;
			animation-duration: 0.5s;
			-webkit-animation-fill-mode: forwards;
			animation-fill-mode: forwards;
		}
		.stamp2 {
			background: url('../../../../assets/img/estimate/step02.svg') no-repeat;
			background-size: inherit;
			background-position: 0, 0;
			-webkit-animation-name: pulse;
			animation-name: pulse;
			animation-delay: 2.5s;
			-webkit-animation-delay: 2.5s;
			-webkit-animation-duration: 0.5s;
			animation-duration: 0.5s;
			-webkit-animation-fill-mode: forwards;
			animation-fill-mode: forwards;
		}
		.stamp3 {
			background: url('../../../../assets/img/estimate/step03.svg') no-repeat;
			background-size: inherit;
			background-position: 0, 0;
			-webkit-animation-name: pulse;
			animation-name: pulse;
			animation-delay: 2.5s;
			-webkit-animation-delay: 2.5s;
			-webkit-animation-duration: 0.5s;
			animation-duration: 0.5s;
			-webkit-animation-fill-mode: forwards;
			animation-fill-mode: forwards;
		}
		.stamp4 {
			background: url('../../../../assets/img/estimate/step04.svg') no-repeat;
			background-size: inherit;
			background-position: 0, 0;
			-webkit-animation-name: pulse;
			animation-name: pulse;
			animation-delay: 2.5s;
			-webkit-animation-delay: 2.5s;
			-webkit-animation-duration: 0.5s;
			animation-duration: 0.5s;
			-webkit-animation-fill-mode: forwards;
			animation-fill-mode: forwards;
		}
		strong {
			padding-bottom: 10px;
			z-index: 10;
			position: relative;
		}
		p {
			z-index: 10;
			position: relative;
			span {
				font-weight: bold;
				position: relative;
			}
		}
	}
	.inner {
		max-width: 468px;
		padding: 30px !important;
		margin: 0 auto;
		background-color: $color_white;
		box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
		border-radius: 16px;
		position: relative;
		z-index: 3;

		.btn_consulting {
			:deep(.v-btn__content) {
				font-size: $font_xl;
				font-weight: $fw_bold;
			}
		}
	}
}

:deep(.swiper-wrapper) {
	display: flex;
	align-items: start;
}
.swiper-slide {
	width: 60%;
}
.swiper-pagination {
	width: 586px !important;
	transform: translateX(-50%);
	left: 50% !important;
	position: absolute !important;
}
.swiper-pagination-progressbar {
	background-color: #c0c0c0;
	height: 10px;
}
//   span.swiper-pagination-progressbar-fill {
// 		background-color: #00582C;
// 	}
.swiper-slide:nth-child(2n) {
	width: 40%;
}
.swiper-slide:nth-child(3n) {
	width: 20%;
}
.swiper-slide {
	.slideitem {
		&::after {
			content: '';
			z-index: 4;
			width: 100%;
			height: 100%;
			background-color: #00000071;
			position: absolute;
			top: 0;
			left: 0;
			border-radius: 16px;
		}
	}
	// .item {
	// 	background-color: $color_white;
	// 	border-radius: 16px;
	// 	box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
	// 	.tit {
	// 		font-size: $font_lg;
	// 		font-weight: $fw_bold;
	// 	}
	// 	:deep(.bt) {
	// 		visibility: hidden;
	// 	}
	// }
}

.lastslide {
	:deep(.v-btn) {
		display: block;
	}
}

.swiper-slide-active {
	.slideitem {
		&::after {
			background-color: transparent;
			width: 0;
			height: 0;
		}
	}
	.item {
		//height: 450px;
		:deep(.bt) {
			padding: 0;
			visibility: visible;
		}
	}
}

.slider_progress_bar {
	.bar_area {
		height: 8px;
		border-radius: 16px;
		background-color: #ddd;
		position: relative;
		.gauge {
			width: 25%;
			height: 8px;
			background: linear-gradient(92.38deg, #00ce7d -23.88%, #14aaff 105.96%);
			position: absolute;
			left: 0;
			content: '';
			display: block;
			border-radius: 16px;
			-webkit-transition: all 1s;
			transition: all 1s;
		}
	}
}

.loadingWrap {
	position: absolute;
	height: calc(100vh - 24%);
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	z-index: 10;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

@-webkit-keyframes pulse {
	0% {
		opacity: 0;
	}
	10% {
		opacity: 0.5;
		transform-origin: 50% 50%;
		transform: rotate(-2deg) scale(5);
		transition: all 0.3s cubic-bezier(0.6, 0.04, 0.98, 0.335);
	}
	100% {
		opacity: 1;
		transform: rotate(0deg) scale(1);
	}
}

@media all and (max-width: 1264px) {
	.sns.item {
		border-radius: 0 !important;
	}
}

@media all and (max-width: 768px) {
	.custom_quote {
		.inner {
			border-radius: 16px 16px 0 0 !important;
		}

		.title {
			.stamp {
				top: 5px;
				left: 5px;
			}
		}
	}
}
@media all and (max-width: 470px) {
	.custom_quote {
		.inner {
			border-radius: 16px 16px 0 0 !important;
		}

		.title {
			.stamp {
				top: 5px;
				left: 5px;
			}
		}
	}
}
@media all and (max-width: 380px) {
	.sns.item {
		.tit {
			font-size: $font_normal !important;
		}
	}
}
</style>
