<template>
	<div class="wrapper">
		<v-container
			class="pa-0 py-lg-15 py-md-0 px-0 px-lg-3"
			fluid
		>
			<HomeInteriorStoreListItems
				:next="next"
				:currentestimate="HOME_GET_CURRENT_ESTIMATE_CONTENT"
			/>
		</v-container>
	</div>
</template>

<script>
import common from '@/mixins/common'
import { mapGetters, mapMutations } from 'vuex'

export default {
	name: 'HomeInteriorPriceInquireInterior02',
	mixins: [common],
	props: {
		tabSelect: {
			type: Number,
			default: 0,
		},
	},
	data: () => ({
		next: false,
	}),
	computed: {
		...mapGetters(['HOME_GET_INTERIOR_SELECT_INTERIOR', 'APP_GET_APP_STATE', 'HOME_GET_CURRENT_ESTIMATE_CONTENT']),
		selectInterior() {
			return true
		},
	},
	watch: {},
	created() {},
	mounted() {},
	destroyed() {},
	methods: {
		...mapMutations(['HOME_MU_INTERIOR_SELECT_INTERIOR']),
		closeSheet() {
			// this.HOME_MU_INTERIOR_SELECT_INTERIOR(null)
		},
	},
}
</script>

<style scoped lang="scss"></style>
