<template>
	<div class="wrapper">
		<v-container
			class="pa-0 pt-5 px-3"
			fluid
		>
			<div class="guide_guide">
				<div class="guide_txt">
					<div class="icon">
						<div class="pb-4 pb-md-8">
							<v-img
								max-width="60"
								max-height="54"
								:src="require('@/assets/img/icon/icon_gate_3.png')"
							/>
						</div>
					</div>
					<p class="mb-4 mb-md-7 tit">견적신청 완료</p>
					<p class="mb-4">작해주신 내용 기반으로 상담 신청이 완료되었어요!</p>
				</div>

				<v-col
					cols="12"
					class="pa-0 table"
				>
					<div class="mb-5">
						<v-row class="ma-0 mb-6">
							<v-col
								class="pa-0 th"
								cols="12"
								sm="3"
								lg="2"
								align="start"
								align-self="start"
							>
								<p>상담인테리어점</p>
							</v-col>
							<v-col
								class="pa-0 td"
								cols="12"
								sm="9"
								lg="10"
								align="start"
								align-self="start"
							>
								ㄴㄴ
							</v-col>
						</v-row>

						<v-row class="ma-0 mb-6">
							<v-col
								class="pa-0 mb-3 mb-lg-0 th"
								cols="12"
								lg="2"
								align="start"
							>
								<p>문의 콘텐츠</p>
							</v-col>
							<v-col
								class="pa-0 td"
								cols="12"
								lg="10"
								align="start"
							>
								<v-col class="pa-0">
									<p>1</p>
									<p>2</p>
								</v-col>
							</v-col>
						</v-row>

						<v-row class="ma-0 mb-6">
							<v-col
								class="pa-0 mb-3 mb-lg-0 th"
								cols="12"
								lg="2"
								align="start"
							>
								<p>이름</p>
							</v-col>
							<v-col
								class="pa-0 td"
								cols="12"
								lg="10"
								align="start"
							>
								<v-col class="pa-0"> ㅇㅇ </v-col>
							</v-col>
						</v-row>

						<v-row class="ma-0 mb-6">
							<v-col
								class="pa-0 mb-3 mb-lg-0 th"
								cols="12"
								lg="2"
								align="start"
							>
								<p>연락 받으실 번호</p>
							</v-col>
							<v-col
								class="pa-0 td"
								cols="12"
								lg="10"
								align="start"
							>
								<v-col class="pa-0"> </v-col>
							</v-col>
						</v-row>

						<v-row class="ma-0 mb-6">
							<v-col
								class="pa-0 mb-3 mb-lg-0 th"
								cols="12"
								lg="2"
								align="start"
							>
								<p>문의 내용</p>
							</v-col>
							<v-col
								class="pa-0 td"
								cols="12"
								lg="10"
								align="start"
							>
								<v-col class="pa-0"> 빨리 연락주세요. </v-col>
							</v-col>
						</v-row>
					</div>
				</v-col>

				<v-row class="ma-0 step">
					<v-col
						class="pa-0 item"
						cols="12"
						md="12"
						align="center"
					>
						<span class="py-3 px-5 step_num">
							영업일 기준 3~4일 내에 상담 인테리어점를 통해<br />고객님의 문의 내용을 기반하여 맞춤 전화
							상담이 진행 예정이에요!
						</span>

						<div class="mt-4 mt-md-8 txt">
							<p>
								입력하신 정보는 인테리어점 담당자에게만 전달됩니다. <br />동화자연마루는 인테리어점과
								소비자 직거래 중계 플랫폼으로 <br />판매에 대한 책임이 없습니다.
							</p>
						</div>
					</v-col>
				</v-row>
			</div>
		</v-container>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import common from '@/mixins/common'

export default {
	name: 'GreenDongwhaClientRepo99Finishform',

	mixins: [common],
	data: () => ({}),
	computed: {
		...mapGetters(['AUTH_GET_USER_AUTH']),
	},
	watch: {},
	created() {},
	mounted() {},
	destroyed() {},
	methods: {},
}
</script>

<style scoped lang="scss">
.guide_guide {
	.inner {
		max-width: 1000px;
		padding: 40px 80px 48px !important;
		margin: 0 auto;
		background-color: $color_white;
		box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
		border-radius: 16px;
		.btn_consulting {
			:deep(.v-btn__content) {
				font-size: $font_xl;
				font-weight: $fw_bold;
			}
		}
	}
}
</style>
