<template>
	<div>
		<router-view />
	</div>
</template>

<script>
import { mapMutations } from 'vuex'
import localStorage from '@/utils/localStorage'
import ad_kakao from '@/mixins/ad/kakao'

export default {
	name: 'App',
	mixins: [ad_kakao],
	data: () => ({}),
	watch: {
		'$route.path'(url) {
			this.setUrlHistory(url)
		},
	},
	created() {
		window.addEventListener('resize', this.handleResize)
		window.addEventListener('scroll', this.handleScroll)
		window.addEventListener('load', this.handlePageLoad)
		// ad_kakao /
		this.ad_kakao_homePageScript()
	},
	mounted() {
		this.handleResize()
		this.handleScroll()
		this.setUrlHistory(this.$route.path)
	},
	destroyed() {
		window.removeEventListener('resize', this.handleResize)
		window.removeEventListener('scroll', this.handleScroll)
	},
	methods: {
		...mapMutations(['APP_MU_APP_STATE', 'APP_MU_APP_WIDTH', 'APP_MU_SCROLL_BOTTOM', 'APP_MU_PAGE_LOAD']),
		handleResize() {
			const width = window.innerWidth
			let state = 'web'
			if (600 > width) {
				state = 'mobile'
			} else if (width < 768) {
				state = 'tablet'
			}
			this.APP_MU_APP_STATE(state)
			this.APP_MU_APP_WIDTH(width)
		},
		handleScroll() {
			let bottomOfWindow =
				document.documentElement.scrollTop + window.innerHeight >
				document.documentElement.offsetHeight - 250
			if (bottomOfWindow) this.APP_MU_SCROLL_BOTTOM(1)
		},
		handlePageLoad() {
			this.APP_MU_PAGE_LOAD(true)
		},
		setUrlHistory(url) {
			// dynamic urls
			const checkValue = [
				'housewarming/3d/detail/',
				'housewarming/specialist/detail/',
				'product/detail/',
				'community/knowHow/detail/',
				'community/event/detail/',
				'interior/store/detail/',
			]
			if (checkValue.some(x => url.includes(x))) url = url.split('/').slice(0, -1).join('/')

			let history = localStorage.urlHistory?.get() || []
			history.unshift(url)
			localStorage.urlHistory.set(JSON.stringify(history.slice(0, 10)))
		},
	},
}
</script>
