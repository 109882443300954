<template>
	<div>
		<v-row class="ma-0">
			<v-col
				class="px-0"
				cols="12"
				md="12"
			>
				<v-btn
					v-if="appWidthState"
					:disabled="allowCheck"
					color="#00CE7D"
					block
					height="40"
					x-large
					elevation="0"
					@click="openInquireDrawer"
				>
					<h1
						class="font_lg"
						style="color: #fff"
					>
						간편상담신청하기
					</h1>
				</v-btn>
				<v-btn
					v-else
					:disabled="allowCheck"
					color="#00CE7D"
					block
					height="40"
					large
					elevation="0"
					@click="openInquireDrawer"
				>
					<h3
						class="font_lg"
						style="color: #fff"
					>
						간편상담신청하기
					</h3>
				</v-btn>
			</v-col>
		</v-row>
	</div>
</template>

<script>
import common from '@/mixins/common'
import { mapGetters, mapMutations } from 'vuex'

export default {
	name: 'HomeInteriorPriceItemsInteriorList03',
	mixins: [common],
	props: {
		items: {
			type: Array,
			default: () => [],
		},
		tabSelect: {
			type: Number,
			default: 0,
		},
	},
	data: () => ({
		listState: true,
	}),
	computed: {
		...mapGetters(['APP_GET_APP_WIDTH', 'HOME_GET_INTERIOR_SELECT_INTERIOR']),
		allowCheck() {
			return !this.items.length
		},
		emptyCount() {
			return 3 - Number(this.items.length)
		},
		appWidthState() {
			return this.APP_GET_APP_WIDTH > 960
		},
		chooseStoreItems() {
			return this.items
		},
	},
	watch: {
		APP_GET_APP_WIDTH() {
			this.checkAppState()
			this.changeHeight()
		},
		listState() {
			this.changeHeight()
		},
		tabSelect(val) {
			if (val === 1) this.checkAppState()
		},
	},
	mounted() {
		this.checkAppState()
		this.changeHeight()
	},
	destroyed() {},
	methods: {
		...mapMutations([
			'HOME_MU_INTERIOR_SELECT_INTERIOR',
			'APPBAR_MU_INTERIORSTORE_DRAWER',
			'HOME_MU_INTERIOR_GET_INTERIOR_ITEMS',
		]),
		deleteInterior(item) {
			this.HOME_MU_INTERIOR_SELECT_INTERIOR(item)
		},
		checkAppState() {
			// 화면이 960보다 큰 경우
			this.appWidthState ? (this.listState = true) : (this.listState = false)
		},
		changeHeight() {
			// 화면이 960보다 큰 경우
			if (this.appWidthState) return this.$emit('changeHeight', '90px')
			if (this.listState) this.$emit('changeHeight', '487px')
			else this.$emit('changeHeight', '90px')
		},
		next() {
			this.listState = false
			this.$emit('next')
		},
		openInquireDrawer() {
			if (this.chooseStoreItems?.length !== 0) {
				this.APPBAR_MU_INTERIORSTORE_DRAWER(true)
				this.HOME_MU_CURRENT_ESTIMATE_CONTENT_INTERIORSELECT(this.chooseStoreItems)
			}
		},
	},
}
</script>

<style scoped lang="scss">
.select {
	&_count {
		font-size: 1.2rem;
		color: $color_green_6;
		font-weight: bold;
	}
	&_explain {
		font-size: 0.85rem;
		color: $color_gray_9;
	}
}
.selectedStore {
	padding-right: 8px !important;
	&:last-child {
		padding-right: 0 !important;
	}
}
</style>
