<template>
	<div class="wrapper">
		<v-container
			class="pa-0 pt-5 px-3"
			fluid
		>
			<div class="">
				<v-col
					cols="12"
					class="pa-0 table"
				>
					<div class="mb-5">
						<v-row class="finalCont mb-4">
							<div
								v-if="HOME_GET_INTERIOR_SELECT_PRICE"
								class="innerBox02"
							>
								<div
									v-if="HOME_GET_INTERIOR_SELECT_PRICE?.housetype && selectedItem.pageType === '홈'"
									class="d-flex align-items-center"
								>
									<div class="listItem">
										<p class="font_sm color_gray_6">주거형태</p>
										<p>{{ HOME_GET_INTERIOR_SELECT_PRICE?.housetype }}</p>
									</div>
								</div>
								<div
									v-if="
										HOME_GET_INTERIOR_SELECT_PRICE?.homeTypeItems2 && selectedItem.pageType === '홈'
									"
									class="d-flex align-items-center"
								>
									<div class="listItem">
										<p class="font_sm color_gray_6">제품</p>
										<p>{{ HOME_GET_INTERIOR_SELECT_PRICE?.homeTypeItems2.title }}</p>
									</div>
								</div>
								<div
									v-if="HOME_GET_INTERIOR_SELECT_PRICE?.widthModel && selectedItem.pageType === '홈'"
									class="d-flex align-items-center"
								>
									<div class="listItem">
										<p class="font_sm color_gray_6">전용면적</p>
										<p>{{ HOME_GET_INTERIOR_SELECT_PRICE?.widthModel }}</p>
									</div>
								</div>
								<div
									v-if="
										HOME_GET_INTERIOR_SELECT_PRICE?.est_cost_min &&
										HOME_GET_INTERIOR_SELECT_PRICE?.est_cost_max &&
										selectedItem.pageType === '홈'
									"
									class="d-flex align-items-center"
								>
									<div class="listItem">
										<p class="font_sm color_gray_6">예상견적</p>
										<p>
											{{ HOME_GET_INTERIOR_SELECT_PRICE.est_cost_min }}~{{
												HOME_GET_INTERIOR_SELECT_PRICE.est_cost_max
											}}<span class="constvalue">만원</span>
										</p>
									</div>
								</div>
							</div>
							<div
								v-if="selectedItem.product"
								class="innerBox02"
							>
								<div
									v-if="selectedItem.product?.housetype"
									class="d-flex align-items-center"
								>
									<div class="listItem">
										<p class="font_sm color_gray_6">주거형태</p>
										<p>{{ selectedItem.product?.housetype }}</p>
									</div>
								</div>
								<div
									v-if="exclusive_area"
									class="d-flex align-items-center"
								>
									<div class="listItem">
										<p class="font_sm color_gray_6">전용면적</p>
										<p>{{ exclusive_area[0].text }}</p>
									</div>
								</div>
								<div
									v-if="selectedItem.product?.roomcount"
									class="d-flex align-items-center"
								>
									<div class="listItem">
										<p class="font_sm color_gray_6">방개수</p>
										<p>{{ selectedItem.product?.roomcount }}개</p>
									</div>
								</div>
								<div
									v-if="selectedItem.product?.veranda_yn"
									class="d-flex align-items-center"
								>
									<div class="listItem">
										<p class="font_sm color_gray_6">베란다 확장 여부</p>
										<p>
											{{
												selectedItem.product?.veranda_yn === 'Y'
													? '베란다 확장'
													: '베란다 미확장'
											}}
										</p>
									</div>
								</div>
								<div
									v-if="selectedItem.product?.buildscope"
									class="d-flex align-items-center"
								>
									<div class="listItem">
										<p class="font_sm color_gray_6">시공범위</p>
										<p>{{ selectedItem.product?.buildscope }}</p>
									</div>
								</div>
								<div
									v-if="selectedItem.product?.goods_code"
									class="d-flex align-items-center"
								>
									<div class="listItem">
										<p class="font_sm color_gray_6">시공제품</p>
										<p>{{ filterItems?.text }}</p>
									</div>
								</div>
								<div
									v-if="selectedItem.product?.removal_yn"
									class="d-flex align-items-center"
								>
									<div class="listItem">
										<p class="font_sm color_gray_6">철거유무</p>
										<p>
											{{ selectedItem.product?.removal_yn === 'Y' ? '철거 진행' : '철거 미진행' }}
										</p>
									</div>
								</div>
								<div
									v-if="selectedItem.product?.est_cost_min && selectedItem.product?.est_cost_max"
									class="d-flex align-items-center"
								>
									<div class="listItem">
										<p class="font_sm color_gray_6">예상견적</p>
										<p>
											{{ selectedItem.product?.est_cost_min }}~{{
												selectedItem.product?.est_cost_max
											}}<span class="constvalue">만원</span>
										</p>
									</div>
								</div>
							</div>
							<div class="pt-3">
								<div
									v-if="
										HOME_GET_INTERIOR_GET_INTERIOR_ITEMS.length !== 0 &&
										selectedItem.pageType !== '인테리어점 찾기' &&
										HOME_GET_TAB_SELECT !== 1
									"
								>
									<p class="titType01">상담 인테리어점</p>
									<ul class="innerBox py-1">
										<li
											v-for="(ele, idx) in HOME_GET_INTERIOR_GET_INTERIOR_ITEMS"
											:key="idx"
											class="my-1"
										>
											{{ ele.business_name }}
										</li>
									</ul>
								</div>
								<div
									v-else-if="selectedItem.pageType === '인테리어점 찾기' || HOME_GET_TAB_SELECT === 1"
								>
									<p class="titType01">상담 인테리어점</p>
									<ul class="innerBox py-1">
										<li
											v-for="(ele, idx) in selectedItem.interiorSelect"
											:key="idx"
											class="my-1"
										>
											{{ ele.business_name }}
										</li>
									</ul>
								</div>
								<div v-else-if="selectedItem.pageType === '인테리어점 찾기'">
									<p class="titType01">상담 인테리어점</p>
									<ul class="innerBox py-1">
										<li
											v-for="(ele, idx) in selectedItem.interiorSelect"
											:key="idx"
											class="my-1"
										>
											{{ ele.business_name }}
										</li>
									</ul>
								</div>
								<div v-else>
									<p class="titType01">상담 인테리어점</p>
									<ul class="innerBox py-1">
										<li
											v-for="(ele, idx) in HOME_GET_INTERIOR_SELECT_INTERIOR"
											:key="idx"
											class="my-1"
										>
											{{ ele.business_name }}
										</li>
									</ul>
								</div>
							</div>
							<div
								v-if="selectedItem.item.contents"
								class="askCont"
							>
								<div v-if="selectedItem.pageType">
									<p class="titType02">문의콘텐츠</p>
									<div class="innerBox02">
										<div class="d-flex align-items-center">
											<router-link
												:to="
													selectedItem.pageType.includes('전문가')
														? `/housewarming/specialist/detail/${selectedItem.item.contents.id}`
														: selectedItem.pageType.includes('3D')
														? `/housewarming/3d/detail/${selectedItem.item.contents.id}`
														: selectedItem.pageType.includes('상품')
														? `/product/detail/${selectedItem.item.contents.id}`
														: ''
												"
												><v-img
													eager
													class="mr-2 askcontImg"
													:src="
														selectedItem.item?.image_url
															? selectedItem.item.image_url
															: selectedItem.item.image
													"
												/>
											</router-link>
											<div class="d-flex flex-column justify-center">
												<p class="font_sm color_gray_6">{{ selectedItem.pageType }}</p>
												<p class="contSub">{{ selectedItem.item.contents?.name }}</p>
											</div>
										</div>
									</div>
								</div>
								<div v-else>
									<p class="titType02">제품상세</p>
									<div class="innerBox02">
										<div class="">
											<p class="font_sm color_gray_6">{{ selectedItem.item.kind_name }}</p>
											<p>{{ selectedItem.item.concat_goodsname }}</p>
										</div>
									</div>
								</div>
							</div>
						</v-row>

						<p class="titType03">신청자 정보</p>
						<v-row class="ma-0 my-2">
							<v-col
								class="pa-0 mb-lg-0 th"
								cols="12"
								lg="3"
								align="start"
							>
								<p class="color_gray_6">이름</p>
							</v-col>
							<v-col
								class="pa-0 mb-lg-0 td"
								cols="12"
								lg="9"
								align="start"
							>
								<v-col
									v-if="AUTH_GET_USER_AUTH === 'GUEST'"
									class="pa-0"
								>
									{{ selectedUserinfo?.name }}
								</v-col>
								<v-col
									v-else-if="AUTH_GET_USER_AUTH === 'USER'"
									class="pa-0"
								>
									{{ AUTH_GET_USER?.name }}
								</v-col>
								<v-col
									v-else
									class="pa-0"
								>
									{{ selectedItem?.product.login_name }}
								</v-col>
							</v-col>
						</v-row>
						<v-row class="ma-0 my-2">
							<v-col
								class="pa-0 mb-lg-0 th"
								cols="12"
								lg="3"
								align="start"
							>
								<p class="color_gray_6">휴대폰 번호</p>
							</v-col>
							<v-col
								class="pa-0 mb-lg-0 td"
								cols="12"
								lg="9"
								align="start"
							>
								<v-col
									v-if="AUTH_GET_USER_AUTH === 'GUEST'"
									class="pa-0"
								>
									{{ selectedUserinfo?.hand }}
								</v-col>
								<v-col
									v-else-if="AUTH_GET_USER_AUTH === 'USER'"
									class="pa-0"
								>
									{{ AUTH_GET_USER?.hand }}
								</v-col>
								<v-col
									v-else
									class="pa-0"
								>
									{{ selectedItem?.product.login_id }}
								</v-col>
							</v-col>
						</v-row>
						<v-row class="ma-0 my-2">
							<v-col
								class="pa-0 mb-lg-0 mb-lg-0 th"
								cols="12"
								lg="3"
								align="start"
							>
								<p class="color_gray_6">문의사항</p>
							</v-col>
							<v-col
								class="pa-0 mb-lg-0 td"
								cols="12"
								lg="9"
								align="start"
							>
								<v-col class="pa-0">
									{{
										selectedItem?.askContents !== null
											? selectedItem?.askContents
											: selectedItem?.askContents === null
											? ''
											: selectedItem?.product.content
									}}
								</v-col>
							</v-col>
						</v-row>
					</div>
				</v-col>

				<v-row class="ma-0 step">
					<v-col
						class="pa-0 item"
						cols="12"
						md="12"
						align="left"
					>
						<p class="font_normal color_gray_8">
							영업일 기준 3~4일 내에 상담 인테리어점를 통해<br />고객님의 문의 내용을 기반하여 맞춤 전화
							상담이 진행 예정이에요!
						</p>
						<v-col
							cols="12"
							sm="12"
							lg="12"
							class="pb-2 px-0"
						>
							<CommonButtonsButton01
								name="신청내역 보기"
								color="#cccccc"
								border="#666666"
								outlined
								class-name="edge_4 fw_normal font_16 btn_upload02 confirmFinal"
								height="42"
								radius="4"
								@click="confirmMySubmit"
							/>
						</v-col>
						<p class="py-3 font_sm color_gray_5">
							입력하신 정보는 인테리어점 담당자에게만 전달됩니다. <br />동화자연마루는 인테리어점과 소비자
							직거래 중계 플랫폼으로 <br />판매에 대한 책임이 없습니다.
						</p>
						<v-col
							cols="12"
							sm="12"
							lg="12"
							class="pb-2 px-0"
						>
							<CommonButtonsButton01
								name="확인"
								color="#00592D"
								class-name="edge_4 fw_bold font_16 btn_upload02"
								height="42"
								radius="4"
								@click="backToPage"
							/>
						</v-col>
					</v-col>
				</v-row>
			</div>
		</v-container>
	</div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import common from '@/mixins/common'
import sms from '@/mixins/sms'

export default {
	name: 'HomeInteriorPriceNewFinalinquireCommonFinal',
	mixins: [common, sms],
	props: {
		selectedItem: {
			type: Object,
			default: null,
		},
		selectedUserinfo: {
			type: Object,
			default: null,
		},
	},
	data: () => ({
		homeTypeItems1: [
			{ title: '아파트', value: '아파트' },
			{ title: '오피스텔', value: '오피스텔' },
			{ title: '빌라/주택', value: '빌라/주택' },
		],
		homeTypeItems2: [
			{ title: '강마루', value: 'KC_00001' },
			{ title: '강화마루', value: 'KC_00002' },
			{ title: '원목마루', value: 'KC_00003' },
		],
		good_name_list: {
			gc_01: '강마루',
			gc_02: '강화마루',
			gc_03: '원목마루',
		},
		exclusive_area: null,
		filterItems: null,
	}),
	computed: {
		...mapGetters([
			'AUTH_GET_USER',
			'AUTH_GET_USER_AUTH',
			'HOME_GET_INTERIOR_SELECT_INTERIOR',
			'HOME_GET_INTERIOR_GET_INTERIOR_ITEMS',
			'HOME_GET_INTERIOR_SELECT_PRICE',
			'HOME_GET_TAB_SELECT',
			'HOME_GET_INTERIOR_ESTIMATE_EXCLUSIVE_AREA',
			'HOME_GET_INTERIOR_ESTIMATE_PRODUCT',
		]),
	},
	watch: {},
	async created() {
		await this.getData()
		this.productItemsFilter()
	},
	mounted() {},
	destroyed() {},
	methods: {
		...mapMutations([]),
		...mapActions(['HOME_ACT_INTERIOR_ESTIMATE_EXCLUSIVE_AREA', 'HOME_ACT_INTERIOR_ESTIMATE_PRODUCT']),
		async getData() {
			// 전용면적, 방 데이터 가져오기
			if (this.exclusive_area === null) {
				await this.HOME_ACT_INTERIOR_ESTIMATE_EXCLUSIVE_AREA() // 평수
				await this.HOME_ACT_INTERIOR_ESTIMATE_PRODUCT() //제품목록
			}
			if (this.selectedItem.product?.exclusive_area) {
				if (this.HOME_GET_INTERIOR_ESTIMATE_EXCLUSIVE_AREA.length !== 0) {
					this.exclusive_area = this.HOME_GET_INTERIOR_ESTIMATE_EXCLUSIVE_AREA.filter(
						v => v.exclusive_area === this.selectedItem.product.exclusive_area,
					)
				}
			}

			// 제품 데이거 가져오기
			if (this.HOME_GET_INTERIOR_ESTIMATE_PRODUCT === null) await this.HOME_ACT_INTERIOR_ESTIMATE_PRODUCT() // 제품
		},
		backToPage() {
			if (this.$route.path === '/') {
				this.$emit('closePop', true)
			} else {
				this.$router.go(-1)
			}
		},
		confirmMySubmit() {
			if (this.AUTH_GET_USER_AUTH === 'USER') return this.$router.push('/user?tab=0')
			else if (this.AUTH_GET_USER_AUTH === 'GUEST') return this.$router.push('/interior/mypage/guest')
		},
		productItemsFilter() {
			if (this.selectedItem.product?.goods_code) {
				if (this.HOME_GET_INTERIOR_ESTIMATE_PRODUCT.length !== 0) {
					this.HOME_GET_INTERIOR_ESTIMATE_PRODUCT.filter(x => {
						if (x.goods_code === this.selectedItem.product.goods_code) {
							this.filterItems = x
						}
					})
				}
			}
		},
	},
}
</script>

<style scoped lang="scss">
.askCont {
	.innerBox02 {
		//background-color: $color_gray_1;
		text-align: left;
		padding: 10px;
		background-color: #f8f8f8;
		margin: 10px 0;
		border-radius: 4px;
	}
}
.finalCont {
	border: 1px solid #cccccc;
	border-radius: 8px;
	margin: 0;
	padding: 16px;
	flex-direction: column;
	flex-wrap: initial;
	.innerBox {
		background-color: $color_gray_1;
		padding: 0 10px;
		margin: 10px 0;
		border-radius: 4px;
		li {
			list-style: none;
			text-align: left;
		}
	}
	.innerBox02 {
		//background-color: $color_gray_1;
		text-align: left;
		.listItem {
			width: 100%;
			display: flex;
			align-items: center;
			p {
				&:nth-of-type(1) {
					width: 30%;
					min-width: 80px;
				}
				&:nth-of-type(2) {
				}
			}
		}
	}
}

// 두줄이상 (...)처리
@mixin ellipsis_2 {
	display: -webkit-box !important;
	word-wrap: break-word !important;
	-webkit-line-clamp: 2 !important;
	-webkit-box-orient: vertical !important;
	overflow: hidden !important;
	text-overflow: ellipsis !important;
}

.contSub {
	@include ellipsis_2;
}

.titType01 {
	color: #262626;
	font-size: $font_normal;
	text-align: left;
	font-weight: $fw_bold;
}
.titType02 {
	color: $color_gray_6;
	font-size: $font_normal;
	text-align: left;
}
.titType03 {
	color: $color_font;
	font-size: $font_lg;
	text-align: left;
	font-weight: $fw_regular;
}

.askcontImg {
	flex: none;
	max-width: 56px;
	max-height: 56px;
	height: 56px;
	border-radius: 4px;
}

@media all and (max-width: 959px) {
}
</style>
