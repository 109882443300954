<template>
	<div class="mx-0 my-2">
		<v-col
			cols="12"
			class="py-0 px-0 pb-4 question"
			align="start"
		>
			<span>03</span> 베란다 확장 여부를 알려주세요.
		</v-col>
		<!-- 베란다확장여부 -->
		<v-col
			cols="12"
			class="py-0 px-0 pb-6"
		>
			<div align="start">
				<p class="mb-1 color_gray_7">확장여부</p>
				<CommonCardMenuTypeBtn06
					v-model="allow.type_2"
					:type-items="homeTypeItems2"
					:cols="6"
					:type="'two'"
					:height="56"
				/>
			</div>
		</v-col>

		<v-col
			cols="12"
			class="py-0 px-0 pb-4 question"
			align="start"
		>
			<span>04</span> 원하는 시공 범위를 선택해주세요.
		</v-col>
		<!-- 시공범위 -->
		<v-col
			cols="12"
			class="py-0 px-0 pb-6"
		>
			<div align="start">
				<p class="mb-1 color_gray_7">시공범위</p>
				<CommonCardMenuTypeBtn06
					v-model="allow.type_3"
					:type-items="homeTypeItems3"
					:type="'two'"
					:cols="6"
					:height="56"
				/>
			</div>
		</v-col>
		<div style="top: 100px"></div>
	</div>
</template>

<script>
export default {
	name: 'GreenDongwhaClientRepo02Form',

	props: {
		currentpage: {
			Type: Number,
			require: true,
		},
		homeTypeItems2: {
			type: Array,
		},
		homeTypeItems3: {
			type: Array,
		},
		reset: {
			type: Boolean,
		},
	},

	data() {
		return {
			allow: {
				type_2: null,
				type_3: null,
			},
		}
	},

	computed: {
		homeTypeItems_1_update() {
			return {
				type_2: this.allow.type_2,
				type_3: this.allow.type_3,
			}
		},
	},
	watch: {
		homeTypeItems_1_update(newval) {
			if (newval) {
				if (Object.keys(newval)[0] === 'type_2') {
					this.$emit('hometype1', newval, 'form2')
				}
				if (Object.keys(newval)[0] === 'type_3') {
					this.$emit('hometype1', newval, 'form2')
				}
			}
		},
		reset(newvalue) {
			if (newvalue) {
				this.allow.type_2 = null
				this.allow.type_3 = null
			} else {
				this.allow.type_2 = null
				this.allow.type_3 = null
			}
		},
	},

	mounted() {},

	methods: {},
}
</script>

<style lang="scss" scoped>
.stepprogreess {
	display: flex;
}
.question {
	color: $color_font;
	font-size: $font_xl;
	letter-spacing: -0.7px;
	span {
		font-weight: bold;
		font-size: $font_sub_tit;
	}
}
</style>
